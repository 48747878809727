<template>
  <div class="footer">
      <router-link tag="div" class="imprint-link" to="impressum">Impressum und Datenschutz</router-link>
  </div>
</template>

<script>

export default {
  name: "Footer",
  data() {
    return {
      message: "message from child component."
    };
  }
};
</script>
<style scoped>
.footer {
  font-family: sans-serif;
  display: flex;
  justify-content: center;
}

.imprint-link {
  font-size: .75rem;
  cursor: pointer;
  text-decoration-style: dotted;
}

.imprint-link:hover {
  color: darkgray;
}

</style>