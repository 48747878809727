<template>
  <div class="appartment-one">
    <div class="appartment-headline">Wohnung 1</div>
    <GbGallery :images="images"></GbGallery>
            <div class="price">
      <div class="price-description">
        <span>1 - 2 Personen € 80, jede weitere Person € 15 mehr</span>
      </div>
    </div>
      <ul class="highlights">
        <li>WLAN</li>
        <li>Handtücher</li>
        <li>Bettwäsche</li>        
        <li>Föhn</li>    
        <li>250 Meter zum See</li>        
      </ul>
    <div class="description-text">
      <p>Text edit for testing purpose...</p>
      <p>Mit zwei Schlafzimmern und einem großzügigen Ess- und Wohnbereich ist reichlich Platz für drei Personen. Auf dem Südbalkon kann man von morgens bis abends die Sonne genießen. In der Küche befinden sich unter anderem Spülmaschine, Ceran-Kochfeld, Backofen, Mikrowelle mit Grill, Kaffeemaschine, Wasserkocher, Toaster sowie reichlich Geschirr. Ein großzügiges Badezimmer mit ebenerdiger Dusche komplettiert die Wohnung. Handtücher sind genügend vorhanden.</p>
      <p>Das neu eröffnete Frei- und Seebad Fischbach ist circa 250 Meter entfernt.</p>
    </div>
  </div>
</template>

<script>
import GbGallery from "./GbGallery";

export default {
  components: {
    GbGallery,
  },
  name: "AppartmentOne",
    data() {
    return {
      images: [
        {
          fullViewSrc() {
            return require("../../assets/images/appartment-one/ap1_1.jpg");
          },
          previewSrc() {
            return require("../../assets/images/appartment-one/preview/ap1_1_preview.jpg");
          },
        },
        {
          fullViewSrc() {
            return require("../../assets/images/appartment-one/ap1_2.jpg");
          },
          previewSrc() {
            return require("../../assets/images/appartment-one/preview/ap1_2_preview.jpg");
          },
        },
        {
          fullViewSrc() {
            return require("../../assets/images/appartment-one/ap1_3.jpg");
          },
          previewSrc() {
            return require("../../assets/images/appartment-one/preview/ap1_3_preview.jpg");
          },
        },
        {
          fullViewSrc() {
            return require("../../assets/images/appartment-one/ap1_4_neu.jpg");
          },
          previewSrc() {
            return require("../../assets/images/appartment-one/preview/ap1_4_preview.jpg");
          },
        },
        {
          fullViewSrc() {
            return require("../../assets/images/appartment-one/ap1_5_neu.jpg");
          },
          previewSrc() {
            return require("../../assets/images/appartment-one/preview/ap1_5_preview.jpg");
          },
        },
        {
          fullViewSrc() {
            return require("../../assets/images/appartment-one/ap1_6.JPG");
          },
          previewSrc() {
            return require("../../assets/images/appartment-one/preview/ap1_6_preview.jpg");
          },
        },
                {
          fullViewSrc() {
            return require("../../assets/images/appartment-one/ap1_7.JPG");
          },
          previewSrc() {
            return require("../../assets/images/appartment-one/preview/ap1_7_preview.jpg");
          },
        },
                {
          fullViewSrc() {
            return require("../../assets/images/appartment-one/ap1_8.JPG");
          },
          previewSrc() {
            return require("../../assets/images/appartment-one/preview/ap1_8_preview.jpg");
          },
        },
                {
          fullViewSrc() {
            return require("../../assets/images/appartment-one/ap1_9.jpg");
          },
          previewSrc() {
            return require("../../assets/images/appartment-one/preview/ap1_9_preview.jpg");
          },
        },
                {
          fullViewSrc() {
            return require("../../assets/images/appartment-one/ap1_10.JPG");
          },
          previewSrc() {
            return require("../../assets/images/appartment-one/preview/ap1_10_preview.jpg");
          },
          },
              {  
          fullViewSrc() {
            return require("../../assets/images/appartment-one/ap1_11.JPG");
          },
          previewSrc() {
            return require("../../assets/images/appartment-one/preview/ap1_11_preview.jpg");
          },
          
          }
        

      ], 
         
      };
  },
};
</script>
<style scoped>
.appartment-headline {
  margin-bottom: .2rem;
}
.appartment-one {
 font-family: 'Kulim Park', sans-serif;
}

.price {
  margin-top: 1rem;
}
.price-description, .price-tag, .highlights {
  display: flex;
}

.price-label {

  padding: .1rem;
}

.highlights li {
  padding-right: 3rem;
  list-style-type: square;
}

.appartment-one {
  padding-top: 4.9em;
}
</style>