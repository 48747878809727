import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";

import AppartmentOne from "./components/AppartmentOne";
import AppartmentTwo from "./components/AppartmentTwo";

import StartView from "./components/StartView";

import ContactForm from "./components/ContactForm";
import BookingRequest from "./components/BookingRequest";
import ImprintAndPrivacy from "./components/ImprintAndPrivacy";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: StartView
  },
  {
    path: "/wohnung1",
    component: AppartmentOne
  },
  {
    path: "/wohnung2",
    component: AppartmentTwo
  },
  {
    path: "/kontakt",
    component: ContactForm
  },
  {
    path: "/buchungsanfrage",
    component: BookingRequest
  },
  {
    path: "/impressum",
    component: ImprintAndPrivacy
  }
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

const vm = new Vue({
  el: "#app",
  router,
  render: h => h(App)
});

global.vm = vm;
