<template>
  <div class="cookie-notice">
    <div class="cookie-message">{{message}}</div>
    <button class="ok-button" @click="closeNotice" >Einverstanden</button>
  </div>
</template>

<script>
export default {
  name: "CookieNotice",
  data() {
    return {
      message: "Diese Webseite existiert zur Zeit nur aus Testzwecken. Es können keine Buchungen vorgenommen werden. Diese Webseite verwendet Cookies. Durch die Nutzung dieser Webseite stimmen Sie der Verwendung von Cookies durch den Seitenbetreiber zu."
    };
  },
  methods: {
    closeNotice(){
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.cookie-notice {
  font-family: sans-serif;
  font-size: .75rem;
  z-index: 250;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid royalblue;
}

.ok-button {
  background-color: white;
  cursor: pointer;
}

</style>