<template>
  <div class="child">
    {{message}}
  </div>
</template>

<script>
export default {
  name: "Child",
  data() {
    return {
      message: "message from child component."
    };
  }
};
</script>
<style scoped>

</style>