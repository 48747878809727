<template>
  <div class="appartment-two">
    <div class="appartment-headline">Wohnung 2</div>
    <GbGallery :images="images"></GbGallery>
    <div class="price">

      <div class="price-description">
        <span>1 - 2 Personen € 90, jede weitere Person € 15 mehr</span>
      </div>
    </div>
          <ul class="highlights">
        <li>WLAN</li>
        <li>Handtücher</li>
        <li>Bettwäsche</li>        
        <li>Föhn</li>        
                <li>250 Meter zum See</li>        

      </ul>
    <div class="description-text">
      <p>Mit zwei Schlafzimmern und einem großzügigen Ess- und Wohnbereich ist reichlich Platz für vier Personen. Der 40 Quadratmeter große Südbalkon bietet zusätzlich einen schönen Essplatz und zwei Liegen laden zum Entspannen ein. In der Küche befinden sich unter anderem Spülmaschine, Ceran-Kochfeld, Backofen, Mikrowelle mit Grill, Kaffeemaschine, Wasserkocher, Toaster sowie reichlich Geschirr. Ein großzügiges Badezimmer mit ebenerdiger Dusche komplettiert die Wohnung. Handtücher sind genügend vorhanden.</p>
      <p>Das neu eröffnete Frei- und Seebad Fischbach ist circa 250 Meter entfernt.</p>
    </div>
  </div>
</template>

<script>
import GbGallery from "./GbGallery";

export default {
  components: {
    GbGallery,
  },
  name: "AppartmentTwo",
    data() {
    return {
      images: [
        {
          previewSrc() {
            return require("../../assets/images/appartment-two/preview/ap2_1_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/appartment-two/ap2_1.jpg");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/appartment-two/preview/ap2_2_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/appartment-two/ap2_2.jpg");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/appartment-two/preview/ap2_4_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/appartment-two/ap2_4.jpg");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/appartment-two/preview/ap2_5_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/appartment-two/ap2_5.JPG");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/appartment-two/preview/ap2_6_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/appartment-two/ap2_6.jpg");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/appartment-two/preview/ap2_7_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/appartment-two/ap2_7.JPG");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/appartment-two/preview/ap2_8_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/appartment-two/ap2_8.jpg");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/appartment-two/preview/ap2_9_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/appartment-two/ap2_9.jpg");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/appartment-two/preview/ap2_10_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/appartment-two/ap2_10.jpg");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/appartment-two/preview/ap2_11_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/appartment-two/ap2_11.JPG");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/appartment-two/preview/ap2_12_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/appartment-two/ap2_12.JPG");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/appartment-two/preview/ap2_13_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/appartment-two/ap2_13.jpg");
          },
        },
      ],    
      };
  },
};
</script>
<style scoped>
.appartment-headline {
  margin-bottom: .2rem;
}
.appartment-two {
 font-family: 'Kulim Park', sans-serif;
}

.price {
  margin-top: 1rem;
}
.price-description, .price-tag, .highlights {
  display: flex;
}

.price-label {

  padding: .1rem;
}

.highlights li {
  padding-right: 3rem;
  list-style-type: square;
}

.appartment-two {
  padding-top: 4.9em;
}
</style>