<template>
  <div class="main-navigation">
    <transition name="fade">
      <nav :class="toggled ? 'open' : ''">
        <ul>
          <!---<li class="booking-request">Buchungsanfrage</li>-->
          <!---<li v-for="item in navigationItems" :key="item.id">{{item.name}}</li>-->
          <router-link tag="li" class="booking-request" to="buchungsanfrage">Buchungsanfrage</router-link>
          <router-link
            class="nav-item"
            tag="li"
            v-for="item in navigationItems"
            :key="item.id"
            :to="item.path"
          >
            <span class="nav-item-title">
              {{item.name}}
              <span v-if="item.subTitle" class="nav-item-subtitle">{{item.subTitle}}</span>
            </span>
          </router-link>
        </ul>
      </nav>
    </transition>
  </div>
</template>

<script>
export default {
  name: "MainNavigation",
  props: {
    toggled: Boolean
  },
  data() {
    return {
      navigationItems: [
        { name: "Wohnung 1", subTitle: "60 m²", id: 1, path: "/wohnung1" },
        { name: "Wohnung 2", subTitle: "80 m²", id: 2, path: "/wohnung2" },
        { name: "Kontakt", subTitle: "", id: 3, path: "/kontakt" }
      ]
    };
  }
};
</script>
<style scoped>
.booking-request {
  background-color: lawngreen;
}
nav {
  display: none;
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style: none;
}

nav.open {
  display: block;
}

ul {
  padding: 0;
  margin: 0;
}

nav ul li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style: none;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;

  cursor: pointer;
  font-size: calc(1vw + 1em);
  text-align: center;
  padding: calc(0.4vw + 0.2em);
}

nav ul li:hover {
  background-color: yellowgreen;
}

.fade-enter-active {
  transition: opacity 0.8s;
}

.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media (min-width: 769px) {
  nav {
    display: block;
  }

  nav ul {
    display: flex;
    justify-content: space-around;
  }

  nav ul li {
    text-transform: none;
    font-size: 0.7em;
  }

  nav ul li:hover {
    background-color: inherit;
    border-bottom: 0.1em solid royalblue;
  }

  nav ul li:hover .nav-item-subtitle {
    background-color: inherit;
    display: none;
  }

  .booking-request {
    order: 3;
    border-bottom: 0.1em solid transparent;
  }
  .booking-request:hover {
    background-color: #42f492;
  }

  .nav-item-title {
    position: relative;
  }
  .nav-item-subtitle {
    position: absolute;
    font-size: 0.7em;
    transition: display 5s;
    top: 1.5em;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
</style>