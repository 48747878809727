<template>
  <div class="phone-number">
    <div class="icon">
      <i class="material-icons">phone</i>
    </div>
    <div class="number">07541/41325</div>
  </div>
</template>

<script>
export default {
  name: "PhoneNumber",
  data() {
    return {};
  }
};
</script>
<style scoped>
.phone-number {
  display: flex;
}

.icon > .material-icons {
  font-size: 1.5em !important;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.4);
}

.icon {
  align-self: stretch;
}
.number {
  align-self: stretch;

  font-family: "Montserrat", sans-serif;
  font-size: 1.2em;
}
</style>