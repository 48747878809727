<template>
  <div class="contact-form">
    <label class="name" for="name">Name</label>
    <input type="text" id="name" name="name" class="cool-c" placeholder="Name">
    <label for="email">E-Mail</label>
    <input type="text" id="email" name="email" placeholder="E-Mail-Adresse">

    <label for="message">Nachricht</label>
    <textarea id="message" name="message" placeholder="Schreiben Sie hier Ihre Nachricht"></textarea>

    <input type="submit" value="Abschicken">
  </div>
</template>

<script>
export default {
  name: "ContactForm",
  data() {
    return {
      message: "message from child component."
    };
  }
};
</script>
<style scoped>
.contact-form {
  padding: 4.9em 0.8em 0.8em 0.8em;
  font-family: "Montserrat" sans-serif;
}

.contact-form > label,
input,
textarea {
  width: 100%;
  display: block;
  font-family: sans-serif;
  font-size: 1.25em;
}

input:label.name {
  font-family: "Montserrat" sans-serif !important;
}

::placeholder {
  color: rgba(0, 0, 0, 0.8);
}

textarea {
  height: calc(2em + 24vh);
  resize: vertical;
}

input[type="text"] {
}

.contact-form > label {
  display: none;
}

input[type="text"] {
  margin-bottom: 0.5em;
  font-family: "Montserrat" sans-serif;
  color: #210023;
}

input[type="submit"] {
  font-family: "Montserrat" sans-serif;
  border: none;
  text-transform: capitalize;
  padding: 0.2em 0;
  background-color: royalblue;
  color: white;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: blue;
}

.cool-c {
  background-color: royal-blue;
}
</style>