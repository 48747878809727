<template>
  <div class="imprint-and-privacy">
    <div class="imprint" >
      <Imprint/>
    </div>
    <div class="privacy" >
      <DataPrivacyNotice class="privacy" />
    </div>
  </div>
</template>

<script>
import Imprint from "./Imprint";
import DataPrivacyNotice from "./DataPrivacyNotice";

export default {
  components: {
    Imprint,
    DataPrivacyNotice
  },
  name: "ImprintAndPrivacy",
  data() {
    return {
      message: "message from child component."
    };
  }
};
</script>
<style scoped>
.imprint-and-privacy {
  padding-top: 4.9em;
  display: flex;
}

.imprint {
  width: calc(16rem + 1.5vw);
}

.privacy {
  margin-left: 1rem;
}
</style>