<template>
  <div class="wrapper">
    <div class="gallery">
      <template v-for="(image, index) in images">
        <img  
            :key="index" 
            :src="image.previewSrc()" 
            @click="selectImage(image, index)" 
            />
      </template>
    </div>
        <ModalView 
          @image-click="selectNextImage" 
          v-if="shouldShowModal" 
          :closeModal="closeModal" 
          :selectedImage="selectedImage[0]"
        >
        </ModalView>
  </div>
</template>

<script>
import ModalView from "./ModalView";

export default {
  name: "Gallery",
    components: {
    ModalView,
  },

 props: ['images'],

  data() {
    return {
      shouldShowModal: false,
    };

    selectedImage: null;
  },
  methods: {
    closeModal() {
      this.shouldShowModal = false;
    },
    selectImage(image, index) {
      this.selectedImage = [image, index];
      this.shouldShowModal = true;
    },
    selectNextImage(){
      let [,currentIndex] = this.selectedImage;
      currentIndex = currentIndex >= this.images.length - 1 ? 0 : currentIndex;
      this.selectImage(this.images[currentIndex + 1],currentIndex + 1);
      this.$forceUpdate();
    }
  },
};
</script>
<style scoped>
.wrapper {
  display: grid;
  align-items: center;
}
.gallery {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
img {
  width: auto;
  height: 6em;
  margin: 0.02em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

</style>