<template>
  <div class="start-view">
          <div class="gallery">
      <template v-for="(image, index) in images">
        <img :key="image.previewSrc()" :src="image.previewSrc()" @click="selectImage(image, index)" />
        <div class="img-hover" :key="index"></div>
      </template>
    </div>
    <ModalView v-if="shouldShowModal" :closeModal="closeModal" :selectedImage="selectedImage[0]" @image-click="selectNextImage"></ModalView>
  </div>
</template>

<script>
import ModalView from "./ModalView";

export default {
  name: "StartView",
    components: {
    ModalView
  },
  data() {
    return {
      shouldShowModal: false,
      selectedImage: null,
      images: [
        {
          previewSrc() {
            return require("../../assets/images/start/preview/start1_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/start/start1.jpg");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/start/preview/start5_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/start/start5.jpg");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/start/preview/start6_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/start/start6.jpg");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/start/preview/start4_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/start/start4.jpg");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/start/preview/start9_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/start/start9.JPG");
          },
        },
        {
          previewSrc() {
            return require("../../assets/images/start/preview/start8_preview.jpg");
          },
          fullViewSrc() {
            return require("../../assets/images/start/start8.JPG");
          },
        },
      ],
      
    };

    
  },

  methods: {
    closeModal() {
      this.shouldShowModal = false;
    },
    selectImage(image, index) {
      this.selectedImage = [image, index];
      this.shouldShowModal = true;
    },
    selectNextImage(){
      let [,currentIndex] = this.selectedImage;
      currentIndex = currentIndex >= this.images.length - 1 ? 0 : currentIndex;
      this.selectImage(this.images[currentIndex + 1],currentIndex + 1);
      this.$forceUpdate();
    }
}
}
</script>
<style scoped>
.start-view {
  display: grid;
  align-items: center;
}
.gallery {
    margin-top: 5rem;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
img {
  width: auto;
  height: 12em;
  margin: 0.02em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
</style>