<template>
  <div class="booking-request">
    <div class="contact-form">
      <div class="headline-major">Ihre Daten</div>
      <label class="name" for="name">Vor- und Nachname</label>
      <input type="text" id="name" name="name" placeholder="Vor- und Nachname" />

      <label for="street">Straße</label>
      <input type="text" id="street" name="email" placeholder="Straße" />

      <label for="email">E-Mail</label>
      <input type="text" id="email" name="email" placeholder="E-Mail-Adresse" />

      <label for="zip-code">PLZ</label>
      <input type="text" id="zip-code" name="zip-code" placeholder="Postleitzahl" />

      <label for="email">Stadt</label>
      <input type="text" id="city" name="city" placeholder="Wohnort" />

      <label for="email">E-Mail</label>
      <input type="text" id="email" name="email" placeholder="E-Mail-Adresse" />

      <label for="phone">Telefon</label>
      <input type="text" id="phone" name="phone" placeholder="Telefonnummer" />

      <div class="select-appartment">
        <div class="headline-major">Welche der Ferienwohnungen bevorzugen Sie?</div>
        <div class="appartment appartment-1">
          <div class="appartment-1-label" @click="appartmentOneChecked = !appartmentOneChecked">
            Wohnung 1
            <span class="appartment-size">60m²</span>
          </div>
          <div class="checkbox-container">
            <input
              type="checkbox"
              name="appartment1"
              value="appartment1"
              :checked="appartmentOneChecked"
            />
          </div>
        </div>
        <div class="appartment appartment-2">
          <div class="appartment-2-label" @click="appartmentTwoChecked = !appartmentTwoChecked">
            Wohnung 2
            <span class="appartment-size">80m²</span>
          </div>
          <div class="checkbox-container">
            <input
              type="checkbox"
              name="appartment2"
              value="appartment2"
              :checked="appartmentTwoChecked"
            />
          </div>
        </div>
      </div>

      <DatePicker></DatePicker>

      <label for="persons">Anzahl der Personen</label>
      <input type="number" id="persons" name="persons" placeholder="Anzahl der Personen" />

      <label for="message">Nachricht</label>
      <textarea id="message" name="message" placeholder="Schreiben Sie hier Ihre Nachricht"></textarea>

      <input type="submit" value="Abschicken" />
    </div>
  </div>
</template>

<script>
import DatePicker from "./DatePicker";
export default {
  components: {
    DatePicker
  },
  name: "BookingRequest",
  data() {
    return {
      fromDate: [2019, 5, 22],
      toDate: [2019, 5, 27],
      appartmentOneChecked: false,
      appartmentTwoChecked: false
    };
  },
  methods: {
    openDatePicker() {
      this.datePickerOpen = true;
    },
    closeDatePicker() {
      this.datePickerOpen = false;
    }
  },

  computed: {
    dateTransformer: {
      // getter
      get: function() {
        const [y, m, d] = this.fromDate;
        return `${d}.${m}.${y}`;
      },
      // setter
      set: function(newValue) {
        const properDate = newValue
          .split(".")
          .filter(elem => isNaN(elem) === false);
        if (properDate.length === 3) {
          const [d, m, y] = properDate;
          this.fromDate = [y, m, d];
        }
      }
    }
  },
  beforeDestroy() {
    console.log("over and out");
  }
};
</script>
<style scoped>
h2,
h4,
.headline {
  font-family: sans-serif !important;
}

.appartment-size {
  font-size: 0.6em;
  margin-left: calc(1vw);
}

.contact-form {
  padding: 4.9em 0.8em 0.8em 0.8em;
  font-family: "Montserrat", sans-serif;
}

.contact-form > label,
input,
textarea {
  width: 100%;
  display: block;
  font-family: sans-serif;
  font-size: 1.25em;
}

input:label.name {
  font-family: "Montserrat" sans-serif !important;
}

.contact-form > label {
  display: none;
}

::placeholder {
  color: rgba(0, 0, 0, 0.8);
}

textarea {
  height: calc(2em + 24vh);
  resize: vertical;
}

input[type="text"],
input[type="number"] {
  margin-bottom: 0.5em;
  font-family: "Montserrat" sans-serif;
  color: #210023;
}

input[type="checkbox"] {
  height: 1.2em;
  width: 1.2em;
}

input[type="submit"] {
  font-family: "Montserrat" sans-serif;
  border: none;
  text-transform: capitalize;
  padding: 0.2em 0;
  background-color: royalblue;
  color: white;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: blue;
}

.date-picker-wrapper {
  position: relative;
}

.date-picker-wrapper .date-picker {
  position: absolute;
  z-index: 500;
  background-color: white;
}

.time-span-container {
  display: flex;
  justify-content: space-between;
}

.time-span-container input {
}

.headline-major {
  color: royalblue;
}

.headline-major:first-child {
  margin-bottom: 0.7vw;
}

.select-appartment {
  font-family: "Montserrat", sans-serif;
  margin: 1.5em auto;
}

.select-appartment > div {
  margin: 0.6em auto;
}

.appartment {
  display: flex;
}

.appartment > div:first-child {
  width: 20%;
}
</style>