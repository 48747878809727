<template>
  <div class="app">
    <div class="header">
      <div class="top-section">
        <Logo></Logo>
        <HamburgerIcon class="hamburger" :toggle="toggle" :toggled="toggled"></HamburgerIcon>
      </div>
      <MainNavigation :toggled="toggled"></MainNavigation>
    </div>
    <router-view></router-view>
    <div >
    <div class="footer" v-if="$route.path !== '/impressum'">
      <Footer/>
    </div>
    <div class="cookie-notice">
      <CookieNotice v-if="showCookieNotice" @close="closeCookieNotice"/>
    </div>
  </div>
</template>

<script>
import Child from "./components/Child";
import SlideCenter from "./components/SlideCenter";
import HamburgerIcon from "./components/HamburgerIcon";
import Logo from "./components/Logo";
import MainNavigation from "./components/MainNavigation";
import PhoneNumber from "./components/PhoneNumber";
import CookieNotice from "./components/CookieNotice"
import Footer from "./components/Footer"

export default {
  components: {
    Child,
    SlideCenter,
    HamburgerIcon,
    Logo,
    MainNavigation,
    PhoneNumber,
    CookieNotice,
    Footer
  },
  name: "app",
  data() {
    return {
      toggled: false,
      showCookieNotice: true
    };
  },
  methods: {
    toggle() {
      this.toggled = !this.toggled;
    },
    closeCookieNotice() {
      this.showCookieNotice = false;
    }
  },

  created() {
  }
};
</script>
<style>
:root {
  box-sizing: border-box;
  font-size: calc(1vw + 0.7em);
  font-family: "Montserrat" sans-serif;
}

*,
*::before,
*::after {
  font-family: "Montserrat" sans-serif;
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat" sans-serif;
}

.cookie-notice {
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
  z-index: 100;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 26;
  background-color: rgba(255, 255, 255, 0.8);
}

.footer {
  margin: 1rem 0 .25rem 0;
}

input,
textarea {
  border: none;
  border-bottom: 0.02em solid rgba(72, 75, 104, 0.55);
  /* rgba(227, 252, 0, 0.15)*/
  /*background-color: rgba(227, 252, 0, 0.15);*/
  /* background-color: rgba(66, 158, 244, 0.06);*/
}

::placeholder {
  color: rgba(0, 0, 0, 0.25);
  font-weight: thin;
  font-size: 0.65em;
}

.top-section {
  display: flex;
  justify-content: space-between;
  margin: 1em;
}

input:focus::placeholder {
  border: none;
  color: green;
  font-size: 0.4em;
}

label {
  display: none;
}

h2,
h4 {
  font-family: "Montserrat" sans-serif !important;
  color: darkblue;
  font-weight: normal;
}

.name {
  display: none;
}
@media (min-width: 769px) {
  .hamburger {
    display: none;
  }
  .header {
    display: flex;
  }

  .main-navigation {
    flex-grow: 2;
    margin-top: 1.7em;
  }
}

textarea {
  border: 0.02em solid rgba(72, 75, 104, 0.55);
  margin-top: 1em;
}
</style>