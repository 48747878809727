<template>
  <div class="hamburger-icon" @click="toggle">
    <div class="line line1" :class="this.toggled? 'toggled':''"></div>

    <div class="line line2" v-if="!toggled"></div>

    <div class="line line3" :class="this.toggled? 'toggled':''"></div>
  </div>
</template>

<script>
export default {
  props: {
    toggle: Function,
    toggled: Boolean
  },
  name: "HamburgerIcon",
  data() {
    return {};
  },
  methods: {}
};
</script>
<style scoped>
.hamburger-icon {
  position: relative;
  cursor: pointer;
  display: inline-block;
  height: calc(0.6vw + 2em);
}

.line {
  width: calc(1.3vw + 3em);
  height: calc(0.6vw + 0.35em);
  background-color: black;
  margin: 0.35em 0;
  transition: transform 0.2s;
}

.hamburger-icon .line.line1.toggled {
  position: relative;
  transform: rotate(30deg);
  margin: 0.85em 0;
}

.hamburger-icon .line.line3.toggled {
  position: absolute;
  transform: rotate(-30deg);
  top: 0.85em;
}

@media (min-width: 769px) {
  .hamburger-icon {
    display: none;
  }
}

.line:first-child,
.line:last-child {
  margin: 0;
}
</style>