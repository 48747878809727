<template>
  <div class="slide-center">
    <transition name="fade">
      <img v-if="show" class="img first" :src="images[0].src()" key="frst">
    </transition>
    <transition name="fade">
      <img class="img second" :src="images[1].src()" key="scnd">
    </transition>
    <div class="arrow-left"></div>
    <div class="arrow-right"></div>
  </div>
</template>

<script>
export default {
  name: "SlideCenter",
  data() {
    return {
      messages: ["Urlaub am Bodensee", "Die beste Wahl", "Seit 2000"],
      show: true,
      images: [
        {
          name: "Bild 1",
          src() {
            return require("../../assets/images/bild1.jpg");
          },
          show: true
        },
        {
          name: "Bild 2",
          src() {
            return require("../../assets/images/bild2.jpg");
          },
          show: false
        },
        {
          name: "Bild 3",
          src() {
            return require("../../assets/images/bild3.jpg");
          },
          show: false
        }
      ]
    };
  },
  methods: {
    nextImage() {
      const [head, ...tail] = this.images;
      this.images = [...tail, head];
    },
    handler() {
      this.show = true;
      const duration = 5200;

      setTimeout(() => {
        this.nextImage();
        this.show = false;

        setTimeout(this.handler, duration);
      }, duration);
    }
  },

  created() {
    this.handler();
  }
};
</script>
<style scoped>
.slide-center {
  justify-content: center;
  /*position: relative;*/
}

.img {
  margin: auto;
  height: auto;
  width: 99%;
  border-radius: 3px;
  /*
  position: absolute;
  */
}

.first {
  z-index: 10;
}

.second {
  /*

  position: absolute;
  */
}

.arrow-left {
  float: left;
  width: 10%;
  opacity: 0.25;
  background: black;
  /*
  position: absolute;
  */
  z-index: 11;
  height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 3.12s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>