<template>
  <div>
    <label for="time-span" class="time-span-label">Zeitraum &#128197;</label>
    <div class="time-span-container">
      <input
        type="text"
        id="time-span"
        name="time-span"
        placeholder="von"
        @click="datePickerOpen = true"
        v-model="fromDateTransformer"
      />
      <input
        type="text"
        id="time-span"
        name="time-span"
        placeholder="bis"
        @click="datePickerOpen = true"
        v-model="toDateTransformer"
        disabled
      />
    </div>

    <div v-if="datePickerOpen" class="date-picker-wrapper" :class="{'date-picker-wrapper--align-right': chosenDates.length > 0}">
      <div class="date-picker">
        <div class="toast-wrap">
          <div
            @click="toastWidthProgress"
            class="toast-progress"
            :style="`width: ${this.toastWidth}%`"
          ></div>
        </div>
        <!--chosenDates: {{chosenDates}}-->
        <div class="navigation">
          <div class="label-previous" @click="previousMonth">
            <div class="arrow-left"></div>zurück
          </div>
          <div class="label-month">{{getGermanNameOfMonth(selectedMonth)}} {{selectedYear}}</div>
          <div class="label-next" @click="nextMonth">
            <div class="arrow-right"></div>vor
          </div>
        </div>

        <div class="current-month"></div>
        <div class="weekdays weekdays-labels">
          <div class="weekday" v-for="weekday in weekdays" :key="weekday">{{weekday}}</div>
        </div>
        <div class="days-of-month weekdays">
          <div
            class="day-of-month"
            :class="[isFirstChosenDate(day.date) ? 'first-chosen':'', 
        isSecondChosenDate(day.date) ? 'second-chosen':'', 
        chosenDates.length === 2 && isInBetweenChosenDates(day.date) ? 'between': '', 
        day.month === 'previous' || day.month === 'next' ? 'gray-day' : '']"
            :ref="day.key"
            v-for="day in getFullRangeForDisplay"
            :key="day.key"
            @click="saveDate(day.date)"
          >{{day.day}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "DatePicker",
  data() {
    return {
      datePickerOpen: false,
      toastWidth: 0,

      currentDate: 0,
      selectedDay: 0,
      selectedMonth: 0,
      selectedYear: 0,

      chosenDates: [],

      weekdays: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
      months: [
        {
          name: "January",
          number: 0,
          translations: {
            german: "Januar"
          },
          days() {
            return 31;
          }
        },
        {
          name: "February",
          number: 1,
          translations: {
            german: "Februar"
          },
          days(year = new Date().getFullYear()) {
            return year % 4 === 0 ? 29 : 28;
          }
        },
        {
          name: "March",
          number: 2,
          translations: {
            german: "März"
          },
          days() {
            return 31;
          }
        },
        {
          name: "April",
          number: 3,
          translations: {
            german: "April"
          },
          days() {
            return 30;
          }
        },
        {
          name: "May",
          number: 4,
          translations: {
            german: "Mai"
          },
          days() {
            return 31;
          }
        },
        {
          name: "June",
          number: 5,
          translations: {
            german: "Juni"
          },
          days() {
            return 30;
          }
        },
        {
          name: "July",
          number: 6,
          translations: {
            german: "Juli"
          },
          days() {
            return 31;
          }
        },
        {
          name: "August",
          number: 7,
          translations: {
            german: "August"
          },
          days() {
            return 31;
          }
        },
        {
          name: "September",
          number: 8,
          translations: {
            german: "September"
          },
          days() {
            return 30;
          }
        },
        {
          name: "October",
          number: 9,
          translations: {
            german: "Oktober"
          },
          days() {
            return 31;
          }
        },
        {
          name: "November",
          number: 10,
          translations: {
            german: "November"
          },
          days() {
            return 30;
          }
        },
        {
          name: "December",
          number: 11,
          translations: {
            german: "Dezember"
          },
          days() {
            return 31;
          }
        }
      ]
    };
  },
  methods: {
    toastWidthProgress() {
      const progress = setInterval(() => {
        this.toastWidth += 5;
        if (this.toastWidth >= 100) {
          clearInterval(progress);
          this.datePickerOpen = false;
        }
      }, 50);
    },
    isInBetweenChosenDates([y, m, d]) {
      const [, [yr, mo, da]] = [...this.chosenDates, [y, m, d]].sort(
        this.sortDates
      );
      return da === d && mo === m && mo && yr === y;
    },
    sortDates(
      [yFirstDate, mFirstDate, dFirstDate],
      [ySecondDate, mSecondDate, dSecondDate]
    ) {
      if (yFirstDate === ySecondDate) {
        if (mFirstDate === mSecondDate) {
          if (dSecondDate > dFirstDate) {
            return -1;
          }
          if (dSecondDate < dFirstDate) {
            return 1;
          }
          if (dSecondDate === dFirstDate) {
            return 0;
          }
        }
        if (mFirstDate > mSecondDate) {
          return 1;
        }
        if (mSecondDate > mFirstDate) {
          return -1;
        }
      }
      if (yFirstDate > ySecondDate) {
        return 1;
      }
      if (yFirstDate < ySecondDate) {
        return -1;
      }
    },

    isFirstChosenDate([y, m, d]) {
      if (this.chosenDates.length === 0) {
        return false;
      }
      if (this.chosenDates) {
        const [[year, month, day]] = this.chosenDates;
        return d === day && m === month && y === year;
      }
    },
    isSecondChosenDate([y, m, d]) {
      if (this.chosenDates.length < 2) {
        return false;
      }
      if (this.chosenDates) {
        const [, [year, month, day]] = this.chosenDates;
        return d === day && m === month && y === year;
      }
    },
    determinePreviousMonth(year, month) {
      let previousMonth;
      let previousMonthsYear;
      if (!(month === 0)) {
        previousMonth = month - 1;
        previousMonthsYear = year;
      }
      if (month === 0) {
        previousMonthsYear = year - 1;
        previousMonth = 11;
      }
      return [previousMonthsYear, previousMonth];
    },
    determineNextMonth(year, month) {
      let nextMonth;
      let nextMonthsYear;
      if (!(month === 11)) {
        nextMonth = this.selectedMonth + 1;
        nextMonthsYear = year;
      }
      if (this.selectedMonth === 11) {
        nextMonthsYear = year + 1;
        nextMonth = 0;
      }
      return [nextMonthsYear, nextMonth];
    },

    saveDate(date) {
      if (this.chosenDates.length === 1) {
        this.toastWidth = 0;

        const [first] = this.chosenDates;
        console.log("+++result is++++", this.sortDates(date, first) === 1);
      }

      if (this.chosenDates.length < 2) {
        if (this.chosenDates.length === 1) {
          const [first] = this.chosenDates;
          if (!this.sortDates(first, date)) {
            console.log("###returned###");
            return;
          } else if (this.sortDates(date, first) === -1) {
            console.log("date:", date, "first:", first);
            this.chosenDates = [date, first];
            this.toastWidthProgress();
            return;
          }
        }
        this.chosenDates = [...this.chosenDates, date];
        if (this.chosenDates.length === 2) {
          this.toastWidthProgress();
        }
      } else {
        this.chosenDates = [date];
        this.toastWidth = 0;
      }
    },
    nextMonth() {
      if (this.selectedMonth === 11) {
        this.selectedMonth = 0;
        this.selectedYear++;
      } else {
        this.selectedMonth++;
      }
    },
    previousMonth() {
      if (this.selectedMonth === 0) {
        this.selectedMonth = 11;
        this.selectedYear--;
      } else {
        this.selectedMonth--;
      }
    },
    getWeekdayOfFirstDayOfMonth(year, month) {
      return new Date(year, month, 1).getDay();
    },
    getGermanNameOfMonth(month) {
      return this.months.find(m => m.number === month).translations.german;
    },
    getCurrentMonth() {
      const month = new Date().getMonth();
      return this.months.find(m => m.number === month);
    },
    createRange(fromNumber, toNumber, arr = []) {
      if (arr.length === 0 && fromNumber > toNumber) {
        return this.createRange(toNumber, fromNumber);
      }
      if (fromNumber === toNumber) {
        return [...arr, toNumber];
      }
      const newArr = [...arr, fromNumber];
      console.log(`call ${fromNumber}, ${toNumber}`);
      return this.createRange(fromNumber + 1, toNumber, newArr);
    },
    howManyDaysToPrepend(weekDayOfFirstOfMonth) {
      return weekDayOfFirstOfMonth === 0 ? 6 : weekDayOfFirstOfMonth - 1;
    },
    howManyDaysToAppend(weekDayOfLastOfMonth) {
      return weekDayOfLastOfMonth === 0 ? 0 : 7 - weekDayOfLastOfMonth;
    },
    isLeapYear(year = new Date().getFullYear()) {
      return year % 4 === 0;
    },
    howManyDaysOfFebruary(year) {
      return this.isLeapYear(year) ? 29 : 28;
    },

    getRangeOfPreviousMonth(year, month) {
      let monthToDisplay;
      let yearToDisplay = year;
      if (month === 0) {
        monthToDisplay = 11;
        yearToDisplay = year - 1;
      } else {
        monthToDisplay = month - 1;
      }

      console.log(monthToDisplay);

      const AmountOfDaysToPrepend = this.howManyDaysToPrepend(
        new Date(year, month, 1).getDay()
      );

      console.log("AmountOfDaysToPrepend", AmountOfDaysToPrepend);

      const AmountOfDaysOfPreviousMonth = this.howManyDaysHasMonth(
        yearToDisplay,
        monthToDisplay
      );

      console.log(AmountOfDaysOfPreviousMonth);

      if (0 === AmountOfDaysToPrepend) {
        return [];
      }

      return this.createRange(
        AmountOfDaysOfPreviousMonth - AmountOfDaysToPrepend + 1,
        AmountOfDaysOfPreviousMonth
      ).map(d => {
        return [d, yearToDisplay];
      });
    },
    getRangeOfNextMonth(year, month) {
      let monthToDisplay;
      let yearToDisplay = year;
      if (month === 11) {
        monthToDisplay = 1;
        yearToDisplay = year + 1;
      } else {
        monthToDisplay = month + 1;
      }

      console.log("noD", this.howManyDaysHasMonth(year, month));

      const amountOfDaysToAppend = this.howManyDaysToAppend(
        new Date(year, month, this.howManyDaysHasMonth(year, month)).getDay()
      );

      console.log(amountOfDaysToAppend);

      if (0 === amountOfDaysToAppend) {
        return [];
      }

      return this.createRange(1, amountOfDaysToAppend).map(d => {
        return [d, yearToDisplay];
      });
    },

    howManyDaysHasMonth(year, month) {
      console.log("Mon", month);
      if (1 === month && this.isLeapYear(year)) {
        return 29;
      }
      if (0 === month) {
        return 31;
      }
      if (1 === month) {
        return 28;
      }
      if (2 === month) {
        return 31;
      }
      if (3 === month) {
        return 30;
      }
      if (4 === month) {
        return 31;
      }
      if (5 === month) {
        return 30;
      }
      if (6 === month) {
        return 31;
      }
      if (7 === month) {
        return 31;
      }
      if (8 === month) {
        return 30;
      }
      if (9 === month) {
        return 31;
      }
      if (10 === month) {
        return 30;
      }
      if (11 === month) {
        return 31;
      }
    }
  },
  created() {
    this.currentDate = new Date();
    this.selectedYear = this.currentDate.getFullYear();
    this.selectedMonth = this.currentDate.getMonth();
  },
  computed: {
    fromDateTransformer: {
      // getter
      get: function() {
        if (this.chosenDates.length === 0) return;
        const [[y, m, d]] = this.chosenDates;
        return `${d}. ${this.getGermanNameOfMonth(m)} ${y}`;
      },
      // setter
      set: function(newValue) {
        const properDate = newValue
          .split(".")
          .map(elem => parseInt(elem))
          .filter(elem => isNaN(elem) === false);
        console.log("proper:", properDate, "chosen:", this.chosenDates);
        if (properDate.length === 3) {
          const [d, m, y] = properDate;
          this.chosenDates[0] = [y, m, d];
          //this.$refs[`${d} ${m}`].click();
          vm.$forceUpdate();
          this.datePickerOpen = false;
          this.datePickerOpen = true;

          console.log("refs", this.$refs["1 5"]);
        }
      }
    },
    toDateTransformer: {
      // getter
      get: function() {
        if (this.chosenDates.length < 2) return;
        const [, [y, m, d]] = this.chosenDates;
        return `${d}. ${this.getGermanNameOfMonth(m)} ${y}`;
      },
      // setter
      set: function(newValue) {
        const properDate = newValue
          .split(".")
          .map(elem => parseInt(elem))
          .filter(elem => isNaN(elem) === false);
        console.log("proper:", properDate, "chosen:", this.chosenDates);
        if (properDate.length === 3) {
          const [d, m, y] = properDate;
          this.chosenDates[1] = [y, m, d];
        }
      }
    },

    currentMonthsRange() {
      return this.createRange(1, this.getCurrentMonth().days());
    },
    getFullRangeForDisplay() {
      const mainMonthForDisplay = this.months.find(
        m => m.number === this.selectedMonth
      );
      const mainMonthsRange = this.createRange(
        1,
        mainMonthForDisplay.days(this.selectedYear)
      ).map(day => {
        return {
          day,
          month: "main",
          key: `${day} ${this.selectedMonth}`,
          date: [this.selectedYear, this.selectedMonth, day]
        };
      });

      const rangeOfPreviousMonth = this.getRangeOfPreviousMonth(
        this.selectedYear,
        this.selectedMonth
      ).map(([day, y]) => {
        return {
          day,
          month: "previous",
          key: `${day} ${this.selectedMonth - 1}`,
          date: [...this.determinePreviousMonth(y, this.selectedMonth), day]
        };
      });
      const rangeOfNextMonth = this.getRangeOfNextMonth(
        this.selectedYear,
        this.selectedMonth
      ).map(([day, y]) => {
        return {
          day,
          month: "next",
          key: `${day} ${this.selectedMonth + 1}`,
          date: [...this.determineNextMonth(y, this.selectedMonth), day]
        };
      });
      return [...rangeOfPreviousMonth, ...mainMonthsRange, ...rangeOfNextMonth];
    }
  }
};
</script>
<style scoped>
.date-picker-wrapper {
  position: relative;
}

.date-picker-wrapper .date-picker {
  position: absolute;
  z-index: 500;
  background-color: white;
}

.time-span-container {
  display: flex;
  justify-content: space-between;
}

.time-span-label {
  margin-bottom: .5rem;
}

.weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.date-picker {
  width: calc(9em + 28vw);
  max-width: 800px;
  /*padding-top: 190vh;*/
  font-family: sans-serif;
  border: 0.05em solid rgba(65, 105, 225, 0.5);
}

.days-of-month {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.navigation {
  display: flex;
  justify-content: space-between;
  background-color: royalblue;
  padding: 1em;
}

.weekdays-labels {
  background-color: rgba(65, 105, 225, 0.5);
  padding: 0.4em 0;
}

.days-of-month {
  padding: 0;
}
.weekday {
  padding: 0 1em;
}
.day-of-month {
  font-family: "Teko" sans-serif;
  padding: 0.5em 1em;
  border: 0.02em solid rgba(65, 105, 225, 0.1);
}

.label-previous,
.label-next,
.label-month {
  position: relative;
  z-index: 2;
  padding: 1em;
  cursor: pointer;
  color: white;
}

.arrow-left {
  z-index: 0;
  position: absolute;
  right: 0.3em;
  top: -0.7em;
  width: 0;
  height: 0;
  border-top: 2.5em solid transparent;
  border-bottom: 2.5em solid transparent;
  border-right: 5em solid #4318ff;
}
.arrow-right {
  z-index: 0;
  position: absolute;
  right: -0.3em;
  top: -0.7em;
  width: 0;
  height: 0;
  border-top: 2.5em solid transparent;
  border-bottom: 2.5em solid transparent;
  border-left: 5em solid #4318ff;
}
.gray-day {
  color: rgba(65, 105, 225, 0.5);
}
.day-of-month {
  cursor: pointer;
}
.day-of-month:hover {
  background-color: lawngreen;
}

.second-chosen {
  border-radius: 0 57% 57% 0;
  border-right: 0.9em solid white;
  background-color: darkgreen;
  color: white;
}

.first-chosen {
  border-radius: 57% 0 0 57%;
  border-left: 0.9em solid white;
  background-color: darkgreen;
  color: white;
}

.between {
  background-color: #00a028;
}

.toast-wrap {
  height: 0.7em;
  background-color: lightgray;
  position: relative;
}

.toast-progress {
  background-color: lawngreen;
  height: 0.7em;
  position: absolute;
}

input[type="text"] {
  margin-bottom: 0.5em;
  font-family: "Montserrat" sans-serif;
  color: #210023;
}

input[type="text"]:focus {
  caret-color: transparent;
}

.date-picker-wrapper {
  display: flex;
  justify-content: start;
  position: relative;
}

.date-picker-wrapper--align-right {
  justify-content: flex-end;
}

.date-picker-wrapper .date-picker {
  position: absolute;
  z-index: 500;
  background-color: white;
}

.time-span-container {
  display: flex;
  justify-content: space-between;
}

label,
input,
textarea {
  width: 100%;
  display: block;
  font-family: sans-serif;
  font-size: 1.25em;
  cursor: pointer;
}

input:label.name {
  font-family: "Montserrat" sans-serif !important;
}

::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

#time-span {
  background-color: darkblue;
  color: white;
}
#time-span:first-child {
  margin-right: 0.5em;
}

#time-span::placeholder {
  color: white;
  text-align: center;
}
label {
  font-size: 1em;
  color: darkblue;
}
</style>