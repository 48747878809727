<template>
  <div class="logo">
    <router-link to="/">
      <span class="part-one">Gabys</span>
      <span class="part-two">Ferienwohnungen</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Logo",
  data() {
    return {
      text: "Gabys Ferienwohnungen"
    };
  }
};
</script>
<style scoped>
.logo {
  user-select: none;
  cursor: pointer;
  font-size: calc(0.6vw + 1.4em);
  position: relative;
}

.logo .part-one {
  font-size: 1.1em;
  font-family: "Varela Round", sans-serif;
  font-weight: bold;
  color: #65419b;
}

.logo .part-two {
  font-family: "Monda", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}
</style>