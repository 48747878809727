<template>
  <div class="imprint">
    <div>
    <div class="imprint-headline">Impressum</div>
    <div class="names">Gaby Bader</div>
    <div class="street">Koberstr. 28</div>
    <div class="town">88048 Friedrichshafen</div>
    <div class="phone">Telefon: 07541/41325</div>
    <div class="email">E-Mail: gabybader@gmx.de</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Imprint",
};
</script>
<style scoped>
.imprint-headline {
  font-weight: bold;
}
.imprint {
  font-family: sans-serif;
  margin-left: 1rem;
}
</style>