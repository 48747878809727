<template>
  <div class="modal-view">
    <div class="close" @click="closeModal">X</div>
      <div class="image-container">
        <img @click="onImageClick" 
            @mouseenter="isImageHovered=true"
            @mouseleave="isImageHovered=false"
            :key="Math.random()" 
            :src="selectedImage.fullViewSrc()" 
            class="highlight-image" />
      </div>
      <div class="arrow-right" 
          @click="onImageClick" 
          :class="{'highlight-arrow': isImageHovered}"
          >&rarr;</div>      
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    closeModal: Function,
    selectedImage: Object | null,
  },
  name: "ModalView",
  data() {
    return {
     isImageHovered: Boolean,
    };
  },
  methods: {
    onImageClick(){
      this.$emit('image-click');
    },
  },
};
</script>
<style scoped>
.modal-view {
  position: fixed;
  z-index: 1500;
  background-color: rgba(0, 0, 0, 0.95);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.highlight-image {
  height: 100vh;
  cursor: pointer;
}

.close {
  position: fixed;
  font-size: 1.4em;
  top: 0em;
  right: 0em;
  color: royalblue;
  cursor: pointer;
  font-family: sans-serif;
  transform: rotate(90deg);
  padding: 0.4em;
}

.arrow-right {
  position: absolute;
  right: .5rem;
  top: 40%;
  font-size: 6rem;
  color: white;
  cursor: pointer;
}

.highlight-arrow {
    color: rgb(79, 79, 219);
}

.image-container {
  min-width: 0;
}

.close:hover, .arrow-right:hover {
  color: rgb(79, 79, 219);
}
</style>